<template>
  <div class="row justify-center bg-indigo-1">
    <q-card flat bordered style="width:300px">
      <q-card-section class="q-gutter-md">
        <q-select
          outlined
          v-model="selKelas"
          label="Kelas"
          :options="kelas"
          @input="
            getNewSiswa();
            siswaTampil = [];
          "
        />
        <div class="q-pb-sm row justify-between items-center">
          <q-input
            dense
            readonly
            outlined
            stack-label
            label="Awal"
            :value="
              typeof selDate === 'string'
                ? momentFilter(selDate)
                : momentFilter(selDate.from)
            "
            style="max-width:125px"
          ></q-input>
          <a>
            <b>-</b>
          </a>
          <q-input
            dense
            readonly
            outlined
            stack-label
            label="Akhir"
            :value="
              typeof selDate === 'string'
                ? momentFilter(selDate)
                : momentFilter(selDate.to)
            "
            style="max-width:125px"
          ></q-input>
          <div class="col-12 row justify-center">
            <q-btn
              flat
              label="Atur Range"
              :disable="!selKelas"
              @click="showDate = true"
            ></q-btn>
          </div>
        </div>
        <q-input outlined v-model="searchTerm" label="Cari Siswa">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-markup-table
        flat
        bordered
        wrap-cells
        dense
        separator="horizontal"
        class="stickyTable bg-grey-4"
        style="height:calc(100vh - 245px);"
      >
        <thead class="bg-indigo-5 text-white text-left">
          <tr class="text-left">
            <th>Nama</th>
            <th class="text-center">
              <strong>H</strong>
              <div class="text-caption text-grey-5">
                {{ filSiswa.summary.hadir }}
              </div>
            </th>
            <th class="text-center">
              <strong>S</strong>
              <div class="text-caption text-grey-5">
                {{ filSiswa.summary.sakit }}
              </div>
            </th>
            <th class="text-center">
              <strong>I</strong>
              <div class="text-caption text-grey-5">
                {{ filSiswa.summary.izin }}
              </div>
            </th>
            <th class="text-center">
              <strong>A</strong>
              <div class="text-caption text-grey-5">
                {{ filSiswa.summary.alfa }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-if="!selKelas">
            <td class="text-center" colspan="5">
              Silahkan Pilih Kelas Dahulu
            </td>
          </tr>
          <tr v-for="(val, i) in filSiswa.siswa" :key="i">
            <td>{{ val.nama }}</td>
            <td class="text-center">
              {{ val.summary.hadir }}
            </td>
            <td class="text-center">
              {{ val.summary.sakit }}
            </td>
            <td class="text-center">
              {{ val.summary.izin }}
            </td>
            <td class="text-center">
              {{ val.summary.alfa }}
            </td>
          </tr>
        </tbody>
      </q-markup-table>
    </q-card>
    <q-dialog v-model="showDate">
      <q-card>
        <q-card-section>
          <q-date v-model="selDate" v-if="minmax" :options="optionsFn" range />
        </q-card-section>
        <q-card-actions align="between">
          <q-btn
            outline
            label="RESET"
            color="primary"
            @click="selDate = { from: null, to: null }"
          ></q-btn>
          <q-btn unelevated label="Ok" color="primary" v-close-popup></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      searchTerm: "",
      newSiswa: [],
      minmax: null,
      selDate: { from: null, to: null },
      showDate: false,

      kelas: [],
      selKelas: null,
    };
  },
  computed: {
    filSiswa() {
      let siswa = JSON.parse(JSON.stringify(this.newSiswa));
      let status = ["Hadir", "Sakit", "Izin", "Alfa"];
      let summary = { hadir: 0, sakit: 0, izin: 0, alfa: 0 };

      if (this.searchTerm != "") {
        siswa = siswa.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          return cNama;
        });
      }

      for (let item of siswa) {
        if (this.selDate.from && this.selDate.to) {
          // console.log("multiple");
          item.kehadiran = item.kehadiran.filter((val) => {
            return (
              moment(val.waktu) >= moment(this.selDate.from) &&
              moment(val.waktu) <= moment(this.selDate.to)
            );
          });
          // console.log(this.selDate);
        }
        if (typeof this.selDate == "string") {
          // console.log("single");
          console.log(item.kehadiran);
          item.kehadiran = item.kehadiran.filter((val) => {
            return (
              moment(val.waktu).format("DDMMYY") ==
              moment(this.selDate).format("DDMMYY")
            );
          });
          console.log(item.kehadiran);
          // console.log(moment(this.selDate));
        }

        item.summary = {};
        for (let stat of status) {
          let temp = item.kehadiran.filter((val) => {
            return val.status == stat;
          }).length;

          item.summary[stat.toLowerCase()] = temp;
          summary[stat.toLowerCase()] = summary[stat.toLowerCase()] + temp;
        }
      }

      console.log({ siswa: siswa, summary: summary });
      return { siswa: siswa, summary: summary };
    },
  },
  async mounted() {
    await this.getKelas();
  },
  methods: {
    optionsFn(date) {
      return (
        moment(date) >= moment(this.minmax.from) &&
        moment(date) <= moment(this.minmax.to)
      );
    },
    momentFilter(somedate) {
      if (somedate) {
        return moment(somedate).format("DD MMM YYYY");
      } else {
        return "-";
      }
    },
    async getKelas() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/penilaian/pembinaan/getkelasAll/${localStorage.getItem("jenjang")}`
      );
      this.kelas = resp.data;
      this.$q.loading.hide();
    },
    async getNewSiswa() {
      let resp = await this.$http.get(
        `/hasil/rekap_absen/new_getsiswa/${
          this.selKelas.value
        }/${localStorage.getItem("id_tahun_ajaran")}`
      );
      this.minmax = { from: resp.data.minmax.start, to: resp.data.minmax.end };
      this.newSiswa = resp.data.siswa;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.sass";
</style>
